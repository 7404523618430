import React, { useState } from "react";
import Dashboard from "../../Dashboard/Dashboard";
import { useNavigate } from "react-router-dom";
import Base_url from "../Base_url";
import { authAxios } from "../../../Services/auth.service";

const User_Insert_data = () => {
  const [fname, setFname] = useState();
  const [lname, setLname] = useState();
  const [email, setEmail] = useState();
  const [social, setSocial] = useState();
  const [user_type, setuser_type] = useState();

  const navigator = useNavigate();
  const back = () => {
    navigator("/home/campaign");
  };

  const updateFname = (e) => {
    setFname(e.target.value);
  };
  const updateLname = (e) => {
    setLname(e.target.value);
  };
  const updateEmail = (e) => {
    setEmail(e.target.value);
  };
  const updateSocial = (e) => {
    setSocial(e.target.value);
  };
  const updateuser_type = (e) => {
    setuser_type(e.target.value);
  };

  const gotoAdd = async (e) => {
    const values1 = {
      first_name: fname,

      last_name: lname,

      email: email,

      social_login: social == "true" ? true : "false",
      user_type: "FOUNDER",
    };
    console.log(values1);

    await authAxios.post(`${Base_url}/api/users/sign-up`, values1);

    navigator("/home/user");
  };

  return (
    <>
      <div className="container-fluid">
        {/* <div className='row'>
          
            <Dashboard 
            f1 = {true}
            f2 = {false}
            />
          
        </div> */}
        <div className="row justify-content-center mb-5">
          <div style={{ borderRadius: "20px", backgroundColor: "#BACDDB" }}>
            <form
              style={{ padding: "50px", borderRadius: "20px" }}
              onSubmit={(e) => {
                e.preventDefault();
                gotoAdd();
              }}
            >
              <h1
                style={{
                  textAlign: "center",
                  color: "#070A52",
                  marginBottom: "20px",
                }}
              >
                ADD User Data
              </h1>

              <label for="exampleInputName" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputName"
                value={fname}
                onChange={updateFname}
              />

              <label for="exampleInputName" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputName"
                value={lname}
                onChange={updateLname}
              />

              <label for="exampleInputRollnum" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputRollnum"
                value={email}
                onChange={updateEmail}
              />

              <label for="exampleInputRegistrationnum" className="form-label">
                Social Login
              </label>
              <select
                class="form-select"
                id="inputGroupSelect04"
                aria-label="Example select with button addon"
                onChange={updateSocial}
                value={social}
              >
                <option selected className="active">
                  Social Login
                </option>
                <option value={true}>True</option>
                <option value={false}>False</option>
              </select>
              <label className="form-label">User Type</label>
              <div class="input-group">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputeRegistrationnum"
                  defaultValue={"FOUNDER"}
                  disabled
                />
              </div>

              <button
                type="submit"
                className="btn btn-success"
                style={{
                  marginTop: "30px",
                  backgroundColor: "#1a83ff",
                  marginRight: "20px",
                }}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={back}
                className="btn btn-success"
                style={{ marginTop: "30px", backgroundColor: "#1a83ff" }}
              >
                Back
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default User_Insert_data;
